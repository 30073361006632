import { FC } from "react";

import { ObjRenderer, PlyRenderer, StlRenderer } from ".";
import { GINGIVA_COLOR, TOOTH_COLOR, ROOT_COLOR } from "../../../constants";
import { useOrientationStore } from "../../../store";
import {
  EntityComponentType,
  EntityGroupType,
  EntityType,
  Orientation,
  VisibilityComponentProps,
} from "../../../types";

export type RenderProps = VisibilityComponentProps & {
  rendererType: EntityType;
};

const getModelOpacityByOrientation = (groupType: EntityGroupType, orientation: Orientation) => {
  const lookingUpThroughMandible = orientation === "up" && groupType === EntityGroupType.MANDIBLE;
  const lookingDownThroughMaxilla = orientation === "down" && groupType === EntityGroupType.MAXILLA;

  const opacity = lookingUpThroughMandible || lookingDownThroughMaxilla ? 0 : 1;

  return opacity;
};

const getModelColorByType = (componentType: EntityComponentType) => {
  switch (componentType) {
    case EntityComponentType.GINGIVA:
      return GINGIVA_COLOR;
    case EntityComponentType.ROOT:
      return ROOT_COLOR;
    default:
      return TOOTH_COLOR;
  }
};

const Renderer: FC<RenderProps> = ({
  rendererType,
  url,
  name,
  isVisible,
  componentType,
  groupType,
  color,
  opacity,
  setSelected,
}) => {
  const { cameraOrientation } = useOrientationStore();

  if (rendererType === EntityType.UNKNOWN) return null;

  const getRenderer = (): JSX.Element => {
    const selectedColor = color || (componentType && getModelColorByType(componentType));
    const selectedOpacity = opacity ?? (groupType && getModelOpacityByOrientation(groupType, cameraOrientation));

    switch (rendererType) {
      case EntityType.OBJ:
        return (
          <ObjRenderer
            url={url}
            name={name}
            isVisible={isVisible}
            componentType={componentType}
            groupType={groupType}
            color={selectedColor}
            opacity={selectedOpacity}
            setSelected={setSelected}
          />
        );

      case EntityType.PLY:
        return (
          <PlyRenderer
            url={url}
            name={name}
            isVisible={isVisible}
            componentType={componentType}
            groupType={groupType}
            color={selectedColor}
            opacity={selectedOpacity}
            setSelected={setSelected}
          />
        );

      case EntityType.STL:
        return (
          <StlRenderer
            url={url}
            name={name}
            isVisible={isVisible}
            componentType={componentType}
            groupType={groupType}
            color={selectedColor}
            opacity={selectedOpacity}
            setSelected={setSelected}
          />
        );
    }
  };

  return getRenderer();
};

export default Renderer;
