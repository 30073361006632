import { FC, useEffect } from "react";

import { MainScene } from "../";
import { useObject3DMeasures } from "../../hooks";
import { useEntitiesStore, EntitiesStore } from "../../store";
import { colorAssigner } from "../../utils";
import { Renderer } from "./components";

const entitiesSelector = (state: EntitiesStore) => state.opacityEntities;
const selectedMeasuresActionSelector = (state: EntitiesStore) => state.setSelectedMeasures;
const setSelectedActionSelector = (state: EntitiesStore) => state.setSelectedEntity;

const StoreDataConnector: FC = ({ children }) => {
  const setSelectedMeasures = useEntitiesStore(selectedMeasuresActionSelector);
  /** The below handles file selection from file panel */
  const result = useObject3DMeasures();

  useEffect(() => {
    setSelectedMeasures(result);
  }, [result]);

  return <>{children}</>;
};

const OpacityEntityRenderer: FC = () => {
  const currentFiles = useEntitiesStore(entitiesSelector);
  const setSelected = useEntitiesStore(setSelectedActionSelector);

  return (
    <MainScene>
      <StoreDataConnector>
        {currentFiles.map(({ type, ...entity }, index) => {
          const color = colorAssigner.assign(entity.url, entity.name);

          return (
            <Renderer key={index} {...entity} rendererType={type} isVisible color={color} setSelected={setSelected} />
          );
        })}
      </StoreDataConnector>
    </MainScene>
  );
};

export default OpacityEntityRenderer;
