import { ReactDOMAttributes } from "@use-gesture/core/types";
import { Vector3 } from "three";

export enum EntityType {
  OBJ = "OBJ",
  PLY = "PLY",
  STL = "STL",
  UNKNOWN = "unknown",
}

export enum EntityComponentType {
  TEETH = "TEETH",
  GINGIVA = "GINGIVA",
  ROOT = "CTTEETH_WITH_ROOTS",
}

export enum EntityGroupType {
  MAXILLA = "MAXILLA",
  MANDIBLE = "MANDIBLE",
}

export enum CameraType {
  Perspective = "Perspective",
  Orthographic = "Orthographic",
}

export interface RotationProps {
  onDrag: (...args: unknown[]) => ReactDOMAttributes;
}

export interface VisibilityComponentProps {
  url: string;
  name: string;
  isVisible: boolean;
  componentType?: EntityComponentType;
  groupType?: EntityGroupType;
  color?: string;
  opacity?: number;
  setSelected?: (fileName: string) => void;
}

export interface OpacityComponentProps {
  url: string;
  opacity: number;
}

export interface EntityBase {
  url: string;
  type: EntityType;
}

export interface EntityObject extends EntityBase {
  name: string;
}

export interface OpacityEntity extends EntityObject {
  opacity: number;
}

export interface EntityGroup extends EntityObject {
  componentType: EntityComponentType;
  groupType: EntityGroupType;
}

export type Group = Record<number, EntityGroup[]>;

export type ParsedQueryValue = string | null | Array<string | null>;

export type SceneLightsPositions = "front" | "right" | "left" | "back" | "top" | "bottom";

export type Object3DMeasures = null | { min: Vector3; max: Vector3; size: Vector3; center: Vector3 };

export type EntityDataType = "OBJ" | "obj" | "STL" | "stl" | "PLY" | "ply";

/** XML Data types  */

export type ParsedData = {
  mandible: Step[];
  maxilla: Step[];
};

export type Step = {
  stepIndex: number;
  collisionPoints: Collision[];
};

export type Collision = {
  positionA: string;
  positionB: string;
  penetration: number;
};

/** Orthoviewer: API response of files list with 3D models to be loeaded */
export type OrthoviewerFileResponse = {
  url: string;
  fileName: string;
  entityType: EntityComponentType;
};

/** Opacityviewer: API response of files list with 3D models to be loeaded */
export type OpacityViewerFileResponse = {
  url: string;
  fileName: string;
  opacity: number;
  modelType: EntityDataType;
};

export type OrthoviewerGroupResponse = {
  mandible: [OrthoviewerFileResponse, OrthoviewerFileResponse];
  maxilla: [OrthoviewerFileResponse, OrthoviewerFileResponse];
};

export type XmlDataResponse = {
  url: string;
  fileName: string;
};

export type OrthoviewerDataResponse = {
  data: {
    currentGroup: number;
    modelType: Extract<EntityType, EntityType.OBJ | EntityType.PLY | EntityType.STL>;
    groups: OrthoviewerGroupResponse[];
    xmlData: XmlDataResponse[];
  };
};

export type OpacityViewerDataResponse = {
  data: {
    model: OpacityViewerFileResponse[];
  };
};

export type Orientation = "right" | "left" | "up" | "down" | "forward" | "back";
