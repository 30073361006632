import {
  EntityType,
  EntityDataType,
  EntityGroup,
  OpacityEntity,
  EntityObject,
  OrthoviewerFileResponse,
  ParsedQueryValue,
  EntityGroupType,
  OpacityViewerFileResponse,
} from "../types";
import { objRegex, plyRegex, stlRegex, opacityRegex } from "./regexp";

const stripOpacityValue = (url: string): string => {
  const found = url.match(opacityRegex);
  if (!found) {
    return url;
  }
  return url.replace(found[0], "");
};
// Normalizes the opacity value from the JSON, from 0-100 range to 0-1 range
const getOpacityValue = (rawNumber: number, type: EntityType, fileName: string): number => {
  if (
    type === EntityType.STL &&
    (fileName.toLowerCase().includes("maxilla") ||
      fileName.toLowerCase().includes("mandible") ||
      fileName.toLowerCase().includes("implant"))
  ) {
    return 0.5;
  }

  if (rawNumber < 0) return 0;
  if (rawNumber > 100) return 1;

  const normalizedNumber = rawNumber / 100;

  return normalizedNumber;
};

const getEntityType = (data: EntityDataType): EntityType => {
  const dataUpper = data.toUpperCase();

  if (Object.values<string>(EntityType).includes(dataUpper)) {
    return dataUpper as EntityType;
  }

  return EntityType.UNKNOWN;
};

const getFileType = (fileUrl: string): EntityType => {
  const fileName = getFileName(fileUrl);

  if (stlRegex.test(fileName)) {
    return EntityType.STL;
  }
  if (plyRegex.test(fileName)) {
    return EntityType.PLY;
  }
  if (objRegex.test(fileName)) {
    return EntityType.OBJ;
  }
  return EntityType.UNKNOWN;
};

const getFileName = (url: string): string => {
  const startIndex = url.lastIndexOf("/") + 1;
  const endIndex = url.indexOf("?") === -1 ? undefined : url.indexOf("?");

  return stripOpacityValue(url).substring(startIndex, endIndex);
};

const sanytize = <T extends ParsedQueryValue>(files: T): string[] => {
  if (!files) {
    return [];
  }
  if (typeof files === "string") {
    return [files];
  }
  return files.filter(url => url) as string[];
};

/** Mapper Orgthoviewer/ Group entity remapper */
export const remapGroupEntityFactory =
  (type: Extract<EntityType, EntityType.OBJ | EntityType.PLY | EntityType.STL>) =>
  ({ url, fileName, entityType }: OrthoviewerFileResponse, groupType: EntityGroupType): EntityGroup => ({
    url,
    type,
    name: fileName,
    componentType: entityType,
    groupType: groupType,
  });

export const remapOpacityEntity = ({
  url,
  fileName,
  opacity,
  modelType,
}: OpacityViewerFileResponse): OpacityEntity => ({
  url,
  type: getEntityType(modelType),
  name: fileName,
  opacity: getOpacityValue(opacity, getEntityType(modelType), fileName),
});

/** Generates array of Entity objects from urls */
export const generateEntityArray = (urls: ParsedQueryValue): EntityObject[] =>
  sanytize(urls).map(url => ({
    name: getFileName(url),
    type: getFileType(url),
    url,
  }));

export const generateBlobOpacityEntityArray = (files: FileList | null): OpacityEntity[] =>
  files
    ? Array.from(files).map(file => ({
        name: file.name,
        url: URL.createObjectURL(file),
        type: getFileType(file.name),
        opacity: 1,
      }))
    : [];
