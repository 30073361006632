import { MeshBasicMaterialParameters, Euler, Quaternion, Vector3 } from "three";

import { type Orientation } from "../types";

export const ROUTES = {
  HOME: "multipartView",
  ORTHO_VIEWER: "/groupView",
  FILE_VIEWER: "/opacityView",
};

export const SCENE_CONFIG = {
  CAMERA_MIN_DISTANCE: 30,
  CAMERA_MAX_DISTANCE: 200,
  CAMERA_MIN_ZOOM: 1,
  CAMERA_MAX_ZOOM: 40,
  CAMERA_POSITION: new Vector3(0, 0, 100),
  CAMERA_ROTATION: new Quaternion().setFromEuler(new Euler(0, 0, -1)),
  CAMERA_FIELD_OF_VIEW: 50,
  CAMERA_ZOOM: 10,
  GIZMO_MARGINS: [85, 85] as [number, number],
  LIGHT_DISTANCE: 100,
  AXES_HELPER_LENGTH: 175,
  GIZMO_ROUTES: [ROUTES.FILE_VIEWER],
};

export const MATERIAL_CONFIG = {
  SHININESS: 100,
  SPECULAR_COLOR: "rgb(20, 20, 20)",
  COLOR: "rgb(255, 255, 255)",
};

export const BOUNDING_BOX_COLOR = "yellow";

export const GINGIVA_COLOR = "rgb(175, 63, 71)";
export const TOOTH_COLOR = "rgb(200, 200, 225)";
export const ROOT_COLOR = "rgb(141, 141, 141)";

export const GRID_CONFIG = {
  SIZE: 150,
  DIVISIONS: 30,
  OFFSET: 5,
  MAIN_COLOR: "#777777",
  CENTER_COLOR: "#ff0000",
  OFFSET_COLOR: "#00ffff",
};

// Direction vectors in world space coordinates
// Used to reorientate the 3D Models
export const DIRECTIONS: Record<Orientation, Vector3> = {
  right: new Vector3(1, 0, 0),
  left: new Vector3(-1, 0, 0),
  up: new Vector3(0, 1, 0),
  down: new Vector3(0, -1, 0),
  forward: new Vector3(0, 0, 1),
  back: new Vector3(0, 0, -1),
};

// Used to rotate the camera by the rotation widget
export const WIDGET_DIRECTIONS: Record<Orientation, Vector3> = {
  right: new Vector3(-1, 0, 0),
  left: new Vector3(1, 0, 0),
  up: new Vector3(0, -1, 0),
  down: new Vector3(0, 1, 0),
  forward: new Vector3(0, 0, 1),
  back: new Vector3(0, 0, -1),
};

export const GROUP_ROTATION: Quaternion = new Quaternion().setFromUnitVectors(DIRECTIONS.forward, DIRECTIONS.up);

export const MILLIMETERS_SUFFIX = "mm";

export const OVERLAY_MESH_BASIC_MATERIAL_SETTINGS: MeshBasicMaterialParameters = {
  depthTest: false,
};
