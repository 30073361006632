import { FC, useEffect, useRef } from "react";

import { useLoader, ThreeEvent, useThree } from "@react-three/fiber";
import { Color, Group, Vector3, MeshPhongMaterial, BufferGeometry } from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import { MATERIAL_CONFIG, GROUP_ROTATION } from "../../../constants";
import { useHandleBoundingBox } from "../../../hooks";
import { VisibilityComponentProps } from "../../../types";
import { getGeometryOffset, isIntersectingGizmo } from "../../../utils";

const OBJRenderer: FC<VisibilityComponentProps> = ({
  url,
  name,
  isVisible,
  componentType,
  groupType,
  color,
  opacity = 1,
  setSelected,
}) => {
  const groupRef = useRef<Group>();
  const innerRef = useRef<Group>();
  const meshRef = useRef<Group>();

  const object = useLoader(OBJLoader, url);

  useHandleBoundingBox(groupRef, isVisible);

  useEffect(() => {
    // eslint-disable-next-line
    object.children.forEach((m: any) => {
      const material = m.material as MeshPhongMaterial;
      material.side = 2;
      material.refractionRatio = 0;
      material.reflectivity = 0;
      material.fog = false;
      material.flatShading = false;
      material.shininess = MATERIAL_CONFIG.SHININESS;
      material.specular = new Color(MATERIAL_CONFIG.SPECULAR_COLOR);
      material.color = new Color(color ? color : MATERIAL_CONFIG.COLOR);

      if (m.geometry) {
        const geometry = m.geometry as BufferGeometry;

        const centerPosition = getGeometryOffset(geometry);
        const centerOffet = new Vector3().copy(centerPosition).multiplyScalar(-1);

        innerRef.current?.position.copy(centerPosition);
        meshRef.current?.position.copy(centerOffet);
      }
    });
  }, [object]);

  useEffect(() => {
    // eslint-disable-next-line
    object.children.forEach((m: any) => {
      const material = m.material;
      material.transparent = opacity !== 1;
      material.depthWrite = opacity === 1;
      material.opacity = opacity;
    });
  }, [opacity]);

  const { raycaster, scene } = useThree();

  const handleFileSelection = (e: ThreeEvent<MouseEvent>): void => {
    if (isIntersectingGizmo(raycaster, scene.children)) return;

    e.stopPropagation();
    setSelected && setSelected(name);
  };

  /**  isVisible hides models that are not related to current Stepper view in Group view */
  if (!isVisible) {
    return null;
  }

  return (
    <group
      ref={groupRef}
      quaternion={GROUP_ROTATION}
      onPointerDown={handleFileSelection}
      userData={{ componentType, groupType }}
    >
      <group ref={innerRef}>
        <group name={name}>
          <primitive ref={meshRef} object={object} />
        </group>
      </group>
    </group>
  );
};

export default OBJRenderer;
