/** Colors provided by client */
const COLORS = [
  "#00AA00",
  "#AA0000",
  "#AA007F",
  "#00ffff",
  "#cc9900",
  "#550000",
  "#00557F",
  "#FF007F",
  "#005500",
  "#FF5500",
];

/** Colors from POC phase */
const SUPPORT_COLORS = [
  "#8EB897",
  "#A2A392",
  "#A4BEF3",
  "#AED4E6",
  "#B2FFA8",
  "#C4AACA",
  "#D7A6B3",
  "#DBD8F0",
  "#E9F5F9",
  "#EEF1DD",
  "#F1DABF",
  "#F4D06F",
  "#FB9F89",
  "#FFFBFF",
  "#F3FFBD",
  "#FE95BB",
  "#94FFBB",
  "#85F6FE",
  "#86AAFE",
  "#D1ADFF",
  "#FFA8A8",
  "#10CAFE",
];

/**
 * Manages color selection from predefined colors
 */
class ColorAssigner {
  private colors: string[];
  private colorMap: Map<string, string>;
  private colorsSet: Set<string>;

  constructor() {
    this.colors = COLORS;
    this.colorMap = new Map();
    this.colorsSet = new Set(COLORS);
  }

  /**
   * Assigns a color upon addition of object3D to the scene
   * @param key object3D identifier for used color
   * @returns color from predefined colors
   */
  public assign(key: string, name: string): string {
    /** Something like internal cache */
    if (this.colorMap.has(key)) {
      return this.colorMap.get(key)!;
    }

    let color = "";
    if (name.toLowerCase().includes("maxilla")) {
      color = "#e3d9d9";
    } else if (name.toLowerCase().includes("mandible")) {
      color = "#e3d9c7";
    } else if (name.toLowerCase().includes("nerve")) {
      color = "#ffff00";
    } else {
      color = this.getRandomColor(this.colorsSet);
    }

    this.colorMap.set(key, color);
    this.colorsSet.delete(color);

    return color;
  }

  /**
   * Resets color for next use, upon file/object3D removal
   * @param key object3D identifier for used color
   */
  public release(key: string): void {
    const color = this.colorMap.get(key);

    if (color) {
      this.colorsSet.add(color);
    }
    this.colorMap.delete(key);
  }

  /**
   * Resets colors for next, upon removal of All files/object3Ds
   */
  public rreleaseAll(): void {
    this.colorMap.clear();
    this.colorsSet = new Set(this.colors);
  }

  /**
   * Randomly picks color from predefined sets
   * @param set color set to pick from
   * @returns randomly picked color
   */
  private getRandomColor(set: Set<string>): string {
    /** We might get duplication of colors here, but is quite unlikely */
    const arr = set.size ? Array.from(set) : SUPPORT_COLORS;

    return arr[Math.floor(Math.random() * arr.length)];
  }
}

export const colorAssigner = new ColorAssigner();
